import { useGetUserGroupsQuery } from '../redux/appQuery'

const useFairyTaleGroups = () => {
  const { data: groups, isLoading, error } = useGetUserGroupsQuery({}) // Fetch groups
  return {
    groups,
    isLoading,
    error,
  }
}

export default useFairyTaleGroups
