import React from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { getUserIdFromToken } from '../../utils/jwt'
import { useGetUserByIdQuery } from '../../redux/appQuery'

interface MediaFileFormProps {
  onSubmit: any
  validationSchema: any
  isLoading: boolean
  groups: { group_id: number; group_name: string }[] // Groups for dropdown
}

const MediaFileFormComponent: React.FC<MediaFileFormProps> = ({
  onSubmit,
  validationSchema,
  isLoading,
  groups,
}) => {
  const { t: tforms } = useTranslation(['formFields'])
  const userId = getUserIdFromToken()
  const { data: authenticatedUser } = useGetUserByIdQuery(userId)

  // Filter visibilities based on role
  const visibilityOptions = [
    { value: 'group', label: 'Group' },
    ...(authenticatedUser?.role?.role_name === 'admin'
      ? [{ value: 'public', label: 'Public' }]
      : []),
  ]

  // Filter groups based on role
  const groupOptions =
    authenticatedUser?.role?.role_name === 'admin'
      ? groups
      : [authenticatedUser?.user_group]

  return (
    <Formik
      initialValues={{ file: null, visibility: 'group', groupId: null }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, handleSubmit, values, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="fileUpload">
            <Form.Label>{tforms('selectFile')}</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  'file',
                  event.target.files ? event.target.files[0] : null,
                )
              }}
              isInvalid={!!errors.file && touched.file}
            />
            <Form.Control.Feedback type="invalid">
              {errors.file}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            controlId="visibilitySelect"
            hidden={!(authenticatedUser?.role?.role_name === 'admin')}
          >
            <Form.Label>{tforms('visibility')}</Form.Label>
            <Form.Control
              as="select"
              name="visibility"
              onChange={e => setFieldValue('visibility', e.target.value)}
              value={values.visibility}
            >
              {visibilityOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {values.visibility === 'group' && (
            <Form.Group
              controlId="groupSelect"
              hidden={!(authenticatedUser?.role?.role_name === 'admin')}
            >
              <Form.Label>{tforms('selectGroup')}</Form.Label>
              <Form.Control
                as="select"
                name="groupId"
                onChange={e => {
                  const value =
                    e.target.value === '' ? null : Number(e.target.value) // Handle null explicitly
                  setFieldValue('groupId', value)
                }}
                value={values.groupId ?? ''} // Set '' when null or undefined
              >
                <option value="">{tforms('selectGroupPlaceholder')}</option>
                {groupOptions?.map(group => (
                  <option key={group?.group_id} value={group?.group_id}>
                    {group?.group_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          <Button
            variant="primary"
            type="submit"
            className="mt-3"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Uploading...</span>
              </>
            ) : (
              tforms('upload')
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default MediaFileFormComponent
