import React, { useId, useState } from 'react'
import { Row, Col, Form, Dropdown, Modal, Button } from 'react-bootstrap'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import { IBasicFormChildComponentprops } from './index'
import MediaFileList from '../../pages/fairytale-media/MediaFileList'
import MediaFileUpload from '../../pages/fairytale-media/MediaFileUpload'

const FairytaleDefaultFormComponent: React.FC<
  IBasicFormChildComponentprops
> = ({
  formProps,
  onChange,
  touched,
  errors,
  values,
  selectValues,
  fileOnChange,
  setFieldValue,
}) => {
  const { t: tcommon } = useTranslation(['common'])
  const { t: tforms } = useTranslation(['formFields'])

  const formId = useId()
  const [showMediaFileListModal, setShowMediaFileListModal] =
    useState<boolean>(false)
  const [showMediaFileUploadModal, setShowMediaFileUploadModal] =
    useState<boolean>(false)
  const [selectedField, setSelectedField] = useState<string>('')

  const handleMediaSelect = (setFieldValue: any, url: string) => {
    setFieldValue(selectedField, url) // Set the selected URL to the field
    setShowMediaFileListModal(false) // Close the modal
  }

  return (
    <>
      <Row xs={1} md={1} lg={2} xl={2} xxl={2}>
        {formProps.map((formProp, idx) => {
          const {
            name,
            required,
            placeholder,
            label,
            disabled,
            type,
            visible,
            description,
            value,
          } = formProp
          if (visible) {
            switch (type) {
              case 'select':
                return (
                  <Form.Group
                    as={Col}
                    controlId={`${formId}-${name}-${idx}`}
                    key={idx}
                  >
                    <Form.Label>{tforms(label)}</Form.Label>
                    <Form.Select
                      data-cy={`${formId}-app-default-select-input-${name}`}
                      aria-label={name}
                      name={name}
                      size="lg"
                      required={required}
                      disabled={disabled}
                      onChange={onChange}
                      value={`${getIn(values, name)}`}
                    >
                      {selectValues[name].map(
                        (
                          option: {
                            label: string
                            value: string
                          },
                          idx: number,
                        ) => (
                          <option key={idx} value={Number(option.value)}>
                            {option.label}
                          </option>
                        ),
                      )}
                    </Form.Select>
                  </Form.Group>
                )
              case 'checkbox':
                return (
                  <Form.Group
                    as={Col}
                    controlId={`${formId}-${name}-${idx}`}
                    key={idx}
                    className="my-3"
                  >
                    <Form.Check
                      data-cy={`${formId}-app-default-select-input-${name}`}
                      aria-label={name}
                      label={tforms(label)}
                      type={type}
                      name={name}
                      checked={getIn(values, name)}
                      value={`${getIn(values, name)}`}
                      required={required}
                      disabled={disabled}
                      onChange={onChange}
                    />
                    {!!getIn(touched, name) && (
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, name)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )
              case 'file':
                return (
                  <Form.Group
                    as={Col}
                    controlId={`${formId}-${name}-${idx}`}
                    key={idx}
                  >
                    <Form.Label>{tforms(label)}</Form.Label>
                    <Form.Control
                      data-cy={`${formId}-app-default-file-input-${name}`}
                      aria-label={name}
                      type={type}
                      name={name}
                      accept="image/*"
                      required={required}
                      disabled={disabled}
                      onChange={fileOnChange}
                    />
                    {description ? (
                      <Form.Text id={`${name}-${idx}-info`} muted>
                        {tforms(description)}
                      </Form.Text>
                    ) : (
                      <></>
                    )}
                    {!!getIn(touched, name) && (
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, name)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )

              case 'imageUrlSelector':
                return (
                  <Form.Group
                    as={Col}
                    controlId={`${formId}-${name}-${idx}`}
                    key={idx}
                    className="mb-2"
                  >
                    <Form.Label>{tforms(label)}</Form.Label>
                    <div className="d-flex align-items-center">
                      <Dropdown className="me-2">
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          id={`${formId}-${name}-dropdown`}
                        >
                          {tforms('selectOption')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setShowMediaFileListModal(true)
                              setSelectedField(name)
                            }}
                          >
                            {tforms('selectFromMediaLibrary')}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedField(name)
                              setShowMediaFileUploadModal(true) // Show upload modal
                            }}
                          >
                            {tforms('uploadNewImage')}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setFieldValue(name, '')
                            }}
                          >
                            {tforms('typeCustomURL')}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Form.Control
                        type="text"
                        aria-label={name}
                        data-cy={`${formId}-app-default-input-${name}`}
                        name={name}
                        required={required}
                        placeholder={tforms(placeholder)}
                        isInvalid={Boolean(
                          getIn(touched, name) && getIn(errors, name),
                        )}
                        disabled={disabled}
                        value={`${getIn(values, name) ?? value}`}
                        onChange={onChange}
                      />
                    </div>
                    {!!getIn(touched, name) && (
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, name)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )
              default:
                return (
                  <Form.Group
                    as={Col}
                    controlId={`${formId}-${name}-${idx}`}
                    key={idx}
                    className="mb-2"
                  >
                    <Form.Label>{tforms(label)}</Form.Label>
                    <Form.Control
                      type={type}
                      size="lg"
                      aria-label={name}
                      data-cy={`${formId}-app-default-input-${name}`}
                      name={name}
                      required={required}
                      placeholder={tforms(placeholder)}
                      isInvalid={Boolean(
                        getIn(touched, name) && getIn(errors, name),
                      )}
                      disabled={disabled}
                      value={`${getIn(values, name) ?? value}`}
                      onChange={onChange}
                    />
                    {!!getIn(touched, name) && (
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, name)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )
            }
          }
        })}
      </Row>

      {/* MediaFile List Modal */}
      <Modal
        show={showMediaFileListModal}
        onHide={() => setShowMediaFileListModal(false)}
        size="xl" // Options are 'sm', 'lg', or 'xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>{tforms('selectFromMedia')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaFileList
            onMediaSelect={(url: string) =>
              handleMediaSelect(setFieldValue, url)
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowMediaFileListModal(false)}
          >
            {tcommon('close')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Media Upload Modal */}
      <Modal
        show={showMediaFileUploadModal}
        onHide={() => setShowMediaFileUploadModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{tforms('uploadNewImage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaFileUpload
            onUpload={(uploadedUrl: string) => {
              setFieldValue(selectedField, uploadedUrl)
              setTimeout(() => {
                setShowMediaFileUploadModal(false)
              }, 2000)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowMediaFileUploadModal(false)}
          >
            {tcommon('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FairytaleDefaultFormComponent
