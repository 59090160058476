import React, { useState } from 'react'

import { Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import FairytaleFormComponent from '../../components/fairytale-form'
import fairytaleYup from '../../yup'
import { useCreateNewPartnerMutation } from '../../redux/appQuery'
import FairyTaleAlert from '../../components/fairytale-alert'

import { t } from 'i18next'
import { PARTNER_FORM_ATTRIB_DATA } from './constants'

interface IAddPartnerProps {
  groupId: string
  onSubmit: () => void
}

const AddDetailsView: React.FC<IAddPartnerProps> = ({ groupId, onSubmit }) => {
  const { t: tcommon } = useTranslation(['common'])
  const [showAlert, setShowAlert] = useState(false)
  const [statusSuccess, setStatusSuccess] = useState(true)
  const [msgError, setMsgError] = useState('error')
  const [createNewPartner] = useCreateNewPartnerMutation()

  const PARTNER_SCHEMA = fairytaleYup.object().shape({
    title: fairytaleYup.string(),
    url: fairytaleYup.string(),
    image_url: fairytaleYup.string(),
  })

  const initialValues = {
    title: '',
    url: '',
    image_url: '',
  }

  const _handleSubmit = async (
    values: any,
    { setStatus, setErrors, setSubmitting, resetForm }: any,
  ) => {
    const { error } = (await createNewPartner({
      body: {
        ...values,
        partner_group_id: groupId,
      },
    })) as {
      data: any
      error: any
    }

    if (!error) {
      onSubmit()
    } else {
      // @ts-ignore
      const message =
        error.data.message.length > 0
          ? error.data.message[0]
          : t('somethingWentWrong')

      setStatusSuccess(false)
      setShowAlert(true)
      setTimeout(() => {
        setStatusSuccess(true)
        setShowAlert(false)
      }, 10000)
      setErrors({ submit: message })
      setMsgError(message)

      return
    }
  }

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">Add new partner</Card.Title>
              </Card.Header>
              <Card.Body>
                <FairyTaleAlert
                  variant={statusSuccess ? 'success' : 'danger'}
                  message={statusSuccess ? tcommon('Add') : msgError}
                  heading=""
                  show={showAlert}
                  name="message-create"
                />
                <FairytaleFormComponent
                  formProps={PARTNER_FORM_ATTRIB_DATA}
                  editMode={false}
                  onSubmit={_handleSubmit}
                  // onHideModal={_handleCloseModal}
                  formSchema={PARTNER_SCHEMA}
                  initialFormValues={initialValues}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default AddDetailsView
