import {
  useCreateNewMediaMutation,
  useDeleteMediaFileMutation,
  useGetMediaFilesQuery,
} from '../redux/appQuery'

const useFairyTaleClubMediaFile = () => {
  const { data: mediaFiles, isLoading, error } = useGetMediaFilesQuery({})
  const [createNewMedia] = useCreateNewMediaMutation()
  const [deleteMediaFile] = useDeleteMediaFileMutation()

  return {
    mediaFiles,
    isLoading,
    error,
    createNewMedia,
    deleteMediaFile,
  }
}

export default useFairyTaleClubMediaFile
