const fi = {
  //Auth
  appNameMain: 'Club application dashboard',
  signinSubTitle: 'SKirjaudu tilillesi jatkaaksesi',
  forgotPassword: 'Unohtuiko salasana?',
  privacy: 'Tietosuojakäytäntö',
  signin: 'Kirjaudu sisään',
  signup: 'Kirjaudu',
  resetPassword: 'Nollaa salasana',
  resetPasswordSubtitle: 'Syötä sähköpostiosoitteesi vaihtaaksesi salasanasi.',
  //Sidebar
  Home: 'Koti',
  Apps: 'Sovellukset',
  Notifications: 'Ilmoitukset',
  Messaging: 'Viestit',
  Ads: 'Mainokset',
  'Apps List': 'Sovellusluettelo',
  'Create an app': 'Luo sovellus',
  'View integrations': 'Näytä integraatiot',
  'Add an integration': 'Lisää integraatio',
  'View notifications': 'Näytä ilmoitukset',
  'Activate new alert': 'Aktivoi uusi hälytys',
  'View scheduled and sent messages': 'Näytä ajoitetut ja lähetetyt viestit',
  'Send an instant message': 'Lähetä pikaviesti',
  'All ads': 'Kaikki mainokset',
  'Creat a new ad': 'Luo uusi mainos',
  'Media and files': 'Media ja tiedostot',
  'All media': 'Kaikki mediat',
  Upload: 'Lataa',
  Users: 'Käyttäjät',
  'Users List': 'Käyttäjäluettelo',
  'New user': 'Uusi käyttäjä',
  createNewUserMainTitle: 'Luo uusi käyttäjä',
  //Users
  firstname: 'Etunimi',
  lastname: 'Sukunimi',
  usersListSubTitle: 'Näytetään kaikki käyttäjät',
  userProfileHeader: 'Käyttäjätiedot',
  userProfileSkills: 'Taidot',
  userProfileAbout: 'Noin',
  //App
  App: 'Sovellus',
  Name: 'Nimi',
  app: 'App',
  appOwner: 'Sovelluksen nimi',
  Status: 'Tila',
  app_id: 'Sovelluksen tunnus',
  app_store_id: 'App Storen tunnus',
  app_title: 'Sovelluksen nimi',
  'Last updated': 'Viimeisin päivitys',
  Actions: 'Toiminnot',
  ownerRole: 'Omistajan rooli',
  appPageSubtitle: 'Uuden sovelluksen perustiedot',
  mediaUploadTitle: 'Lataa uusi media',
  editInProgress: 'Olet muokkaamassa ',
  lowercaseApp: 'app',
  searchFrom: 'Hae osoitteesta',
  records: '... data',
  deleteApp: 'Poista sovellus',
  appsSubTitle: 'Näyttää kaikki Fairytaleen rekisteröidyt sovellukset',
  //App editing
  component: 'Asetukset',
  newFeedCreateText: 'Luo uusi syötekohde',
  feedsNnotifications: 'Syötteet ja ilmoitukset',
  basicInfo: 'Perustiedot',
  token: 'Token',
  user_group: 'Sovelluksen omistaja',
  //Feeds
  feeds: 'Syötteet',
  feedName: 'Syötteen nimi',
  status: 'Tila',
  pushStatus: 'Push-tila',
  deleteFeed: 'Poista syöte',
  feedsListPageHeaderText: 'Syötteet sovellukselle',
  feedPageCreateTitle: 'Luo uusi syöte',
  isAlive: 'Onko integraatio live-tilassa?',
  integrationStatus: 'Integraation tila',
  deleteIntegration: 'Poista integraatio',
  appIntegrations: 'Integraatiot',
  integrationPageCreateTitle: 'Luo uusi sovellusintegraatio',
  integrationName: 'Integroinnin nimi',
  //Ads and campaigns
  tabTitle: 'Mainokset ja kampanjat',
  campaignTitle: 'Otsikko',
  camapignDescription: 'Kuvaus',
  campaignViews: 'Näkymät',
  campaignClicks: 'Napsautukset',
  adLinkLabel: 'Ilmoituksen Linkkietiketti',
  adImageLinkLabel: 'Ilmoituksen Kuvan Linkkietiketti',
  update: 'Päivittää',
  viewCount: 'Näyttökerrat',
  clickCount: 'Klikkauksien Määrä',
  deleteCampaign: 'Poista Kampanja',
  adsDuration: 'Ilmoitusten Kesto',
  numberOfAds: 'Ilmoitusten Määrä',
  clicks: 'Klikkaukset',
  views: 'Näyttökerrat',
  adsDurationDescription:
    'Kampanjan jokaisen ilmoituksen kokonaisaika sekunteina',
  numberOfAdsDescription: 'Kampanjan ilmoitusten kokonaismäärä',
  clicksDescription:
    'Kaikkien kampanjan ilmoitusten yhteinen määrä klikkauksia',
  viewsDescription:
    'Kaikkien kampanjan ilmoitusten yhteinen näyttökertojen määrä',
  //Settings
  settingName: 'Asetetaan nimi',
  settingValue: 'Asetusarvo',
  deleteSetting: 'Poistaa',
  createSettingTitle: 'Luo uusia asetuksia',
  settingsFor: 'Asetukset kohteelle ',
  deleteSettingFor: 'Poista asetus ',
  createNewMessageMainTitle: 'Create new message', // TODO: update language
  messageListSubTitle: 'Displaying all in app messages',
  messageDetailHeader: 'Message details',

  //Media
  actions: 'Toiminnot',
  group_id: 'Ryhmän ID',
  imagePreview: 'Kuvan esikatselu',
  mediaFileListSubtitle: 'Lista ladatuista mediatiedostoista',
  mediaFiles: 'Media tiedostot',
  uploadedAt: 'Ladattu',
  uploadedBy: 'Lähettäjä',
  visibility: 'Näkyvyys',
}

export default fi
