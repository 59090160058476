import { Bounce, Id, toast, ToastOptions } from 'react-toastify'

type ToastType = 'default' | 'success' | 'error' | 'warning' | 'info'

const showToast = (type: ToastType = 'default', msg: string): Id => {
  const setting: ToastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    transition: Bounce,
  }

  switch (type) {
    case 'success':
      return toast.success(msg, setting)
    case 'error':
      return toast.error(msg, setting)
    case 'warning':
      return toast.warning(msg, setting)
    case 'info':
      return toast.info(msg, setting)
    default:
      return toast(msg, setting)
  }
}

export { showToast }
