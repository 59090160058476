import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Spinner } from 'react-bootstrap'
import { Eye, Trash, Check } from 'react-feather'
import FairyTaleTable from '../../components/fairytale-table'
import DataError from '../../components/DataError'
import FairyTaleAlert from '../../components/fairytale-alert'
import Loader from '../../components/Loader'
import { showToast } from '../../components/toast'
import FairytaleModal from '../../components/fairytale-modal'
import { baseUrl, useGetUserByIdQuery } from '../../redux/appQuery'
import useFairyTaleClubMediaFile from '../../hooks/useFairyTaleClubMediaFile'
import { getUserIdFromToken } from '../../utils/jwt'

interface MediaFileListProps {
  onMediaSelect?: (url: string) => void // Callback for media selection
}

const MediaFileList: React.FC<MediaFileListProps> = ({ onMediaSelect }) => {
  const { t: tforms } = useTranslation(['formFields'])
  const { t: tpagetexts } = useTranslation(['pageTexts'])
  const userId = getUserIdFromToken()
  const { data: authenticatedUser } = useGetUserByIdQuery(userId)

  const { mediaFiles, isLoading, error, deleteMediaFile } =
    useFairyTaleClubMediaFile()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [deletingId, setDeletingId] = useState<number | null>(null)

  const _handleCloseModal = () => {
    setShowModal(false)
  }

  const _handleShowModal = useCallback((fileId: number) => {
    setDeletingId(fileId)
    setShowModal(true)
  }, [])

  const _handleDeleteMediaFile = async () => {
    if (deletingId === null) return

    _handleCloseModal() // Close modal before starting deletion

    try {
      await deleteMediaFile(deletingId).unwrap()
      showToast('success', tforms('fileDeleteSuccess'))
    } catch (error) {
      showToast('error', tforms('fileDeleteError'))
    } finally {
      setDeletingId(null)
    }
  }

  const _handleSelectMedia = useCallback(
    (url: string) => {
      if (onMediaSelect) {
        onMediaSelect(url) // Pass the selected media URL to the parent
      }
    },
    [onMediaSelect],
  )

  const MEDIA_FILE_COLUMNS: any[] = useMemo(
    () => [
      {
        id: 'image-preview',
        Header: tpagetexts('imagePreview'),
        Cell: ({ row }: { row: any }) => (
          <img
            src={`${baseUrl}${row.original.filePath}`}
            alt="Media Preview"
            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
          />
        ),
      },
      {
        id: 'uploadedBy',
        Header: tpagetexts('uploadedBy'),
        Cell: ({ row }: { row: any }) =>
          `${row.original.uploadedBy.first_name} ${row.original.uploadedBy.last_name}`,
      },
      {
        id: 'visibility',
        Header: tpagetexts('visibility'),
        accessor: 'visibility',
      },
      {
        id: 'group_id',
        Header: tpagetexts('group_id'),
        Cell: ({ row }: { row: any }) =>
          row.original.visibility === 'group'
            ? row.original?.userGroup?.group_id
            : '',
      },
      {
        id: 'uploadedAt',
        Header: tpagetexts('uploadedAt'),
        Cell: ({ row }: { row: any }) =>
          new Date(row.original.uploadedAt).toLocaleString(),
      },
      {
        id: 'action',
        Header: tpagetexts('actions'),
        Cell: ({ row }: { row: any }) => (
          <div className="d-flex align-items-center">
            <Eye
              className="align-middle mx-3 cursor-pointer"
              size={18}
              data-cy={`media-file-list-actions-view-${row.index}`}
              onClick={() =>
                window.open(`${baseUrl}${row.original.filePath}`, '_blank')
              }
            />
            {onMediaSelect && (
              <Check
                className="align-middle mx-3 cursor-pointer text-success"
                size={18}
                data-cy={`media-file-list-actions-select-${row.index}`}
                onClick={() =>
                  _handleSelectMedia(`${baseUrl}${row.original.filePath}`)
                }
              />
            )}

            {!onMediaSelect &&
              (authenticatedUser?.role?.role_name === 'admin' ||
                authenticatedUser?.user_id ===
                  row.original?.uploadedBy?.user_id) &&
              (deletingId === row.original.upload_id ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <Trash
                  className="align-middle mx-3 cursor-pointer text-danger"
                  size={18}
                  data-cy={`media-file-list-actions-delete-${row.index}`}
                  onClick={() => _handleShowModal(row.original.upload_id)}
                />
              ))}
          </div>
        ),
      },
    ],
    [
      deletingId,
      authenticatedUser,
      onMediaSelect,
      tpagetexts,
      _handleShowModal,
      _handleSelectMedia,
    ],
  )

  if (error) {
    return <DataError />
  }

  return (
    <React.Fragment>
      <Col>
        <FairyTaleTable
          data-cy="media-files-table-main"
          columns={MEDIA_FILE_COLUMNS}
          data={isLoading ? [] : mediaFiles}
          showHeader={true}
          mainTitle={tpagetexts('mediaFiles')}
          subtitle={tpagetexts('mediaFileListSubtitle')}
          useSearchFilter={true}
        >
          <FairyTaleAlert show={isLoading} variant="light">
            <Loader />
          </FairyTaleAlert>
        </FairyTaleTable>
      </Col>
      <FairytaleModal
        showModal={showModal}
        onHideModal={_handleCloseModal}
        modalHeading={tforms('confirmDelete')}
        modalMessage={tforms('areYouSureFile')}
        onClickNegativeButton={_handleCloseModal}
        onClickPositveButton={_handleDeleteMediaFile}
        modalButtonTextPositive={tforms('yes')}
        modalButtonTextNegative={tforms('cancel')}
        name="media-files-modal"
      />
    </React.Fragment>
  )
}

export default MediaFileList
