const se = {
  //Auth
  appNameMain: 'Club application dashboard',
  signinSubTitle: 'Sign in to your account to continue',
  forgotPassword: 'Forgot password?',
  privacy: 'Privacy policy',
  signin: 'Sign in',
  signup: 'Sign up',
  resetPassword: 'Reset password',
  resetPasswordSubtitle: 'Enter your email address to reset your password.',
  //Sidebar
  Home: 'Home',
  Apps: 'Apps',
  Notifications: 'Notifications',
  Messaging: 'Messaging',
  Ads: 'Ads',
  'Apps List': 'Apps List',
  'Create an app': 'Create an app',
  'View integrations': 'View integrations',
  'Add an integration': 'Add an integration',
  'View notifications': 'View notifications',
  'Activate new alert': 'Activate new alert',
  'View scheduled and sent messages': 'View scheduled and sent messages',
  'Send an instant message': 'Send an instant message',
  'All ads': 'All ads',
  'Creat a new ad': 'Creat a new ad',
  'Media and files': 'Media and files',
  'All media': 'All media',
  Upload: 'Upload',
  Users: 'Användare',
  'Users List': 'Användarlista',
  'New user': 'Ny användare',
  createNewUserMainTitle: 'Skapa ny användare',
  //Users
  firstname: 'Förnamn',
  lastname: 'Efternamn',
  usersListSubTitle: 'Visar alla användare',
  userProfileHeader: 'Användarinformation',
  userProfileSkills: 'Kompetens',
  userProfileAbout: 'Handla om',
  //App
  App: 'App',
  Name: 'Name',
  app: 'App',
  appOwner: 'App name',
  Status: 'Status',
  app_id: 'App ID',
  app_store_id: 'App Store ID',
  app_title: 'App name',
  'Last updated': 'Last update',
  ownerRole: 'Owner role',
  appPageSubtitle: 'Basic information of the new app',
  mediaUploadTitle: 'Ladda upp ett nytt media',
  editInProgress: 'You are editing ',
  lowercaseApp: 'app',
  searchFrom: 'Search from',
  records: '... data',
  deleteApp: 'Delete App',
  Actions: 'Handlingar',
  appsSubTitle: 'Displaying all apps registered on Fairytale',
  //App editing
  component: 'Inställningar',
  newFeedCreateText: 'Create new feed item',
  feedsNnotifications: 'Feeds and Notification',
  basicInfo: 'Basic Information',
  token: 'Token',
  user_group: 'App owner',
  //Feeds
  feeds: 'feeds',
  feedName: 'Feed name',
  status: 'Status',
  pushStatus: 'Push status',
  deleteFeed: 'Delete feed',
  feedsListPageHeaderText: 'Feeds for App',
  feedPageCreateTitle: 'Create new feed',
  isAlive: 'Is the integration live?',
  integrationStatus: 'Integration status',
  deleteIntegration: 'Remove integration',
  appIntegrations: 'Integrationer',
  integrationPageCreateTitle: 'Skapa ny appintegration',
  integrationName: 'Integrationsnamn',
  //Ads and campaigns
  tabTitle: 'Annonser och kampanjer',
  campaignTitle: 'Titel',
  camapignDescription: 'Beskrivning',
  campaignViews: 'Visningar',
  campaignClicks: 'Klick',
  deleteCampaign: 'Ta bort kampanj',
  adLinkLabel: 'Annonsens Länketikett',
  adImageLinkLabel: 'Annonsbildens Länketikett',
  update: 'Uppdatera',
  viewCount: 'Visningar',
  clickCount: 'Klickräkning',
  adsDuration: 'Annonsens Varaktighet',
  numberOfAds: 'Antal Annonser',
  clicks: 'Klick',
  views: 'Visningar',
  adsDurationDescription:
    'Den totala tiden i sekunder för varje annons i denna kampanj att köras',
  numberOfAdsDescription: 'Det totala antalet annonser i denna kampanj',
  clicksDescription:
    'Totalt antal gånger alla annonser i denna kampanj har klickats på',
  viewsDescription:
    'Totalt antal gånger alla annonser i denna kampanj har visats',
  //Settings
  settingName: 'Inställningsnamn',
  settingValue: 'Inställningsvärde',
  deleteSetting: 'Radera',
  createSettingTitle: 'Skapa nya inställningar',
  settingsFor: 'Inställningar för ',
  deleteSettingFor: 'Ta bort inställning ',
  createNewMessageMainTitle: 'Create new message', // TODO: update language
  messageListSubTitle: 'Displaying all in app messages',
  messageDetailHeader: 'Message details',

  // TODO Weather
  weathers: 'Weather',
  weatherPlaceValue: 'Place',
  weatherLatitudeValue: 'Latitude',
  weatherLongitudeValue: 'Longitude',
  weatherUrlValue: 'Url',
  deleteWeather: 'Delete',
  createWeatherTitle: 'Create new weather',
  weatherFor: 'Weathers for ',
  deleteFor: 'Delete ',

  placeName: 'Place',
  placeNamePlaceholder: '',
  latitudeName: 'Latitude',
  latitudePlaceholder: '',
  longitudeName: 'Longitude',
  url: 'Url',
  longitudePlaceholder: '',

  partnerGroup: 'Partner groups',

  // Media
  actions: 'Åtgärder',
  group_id: 'Grupp ID',
  imagePreview: 'Förhandsvisning av bild',
  mediaFileListSubtitle: 'En lista över uppladdade mediafiler',
  mediaFiles: 'Mediafiler',
  uploadedAt: 'Uppladdad vid',
  uploadedBy: 'Uppladdad av',
  visibility: 'Synlighet',
}

export default se
